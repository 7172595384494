import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import {
  PimcoreObjectCategory,
  PimcoreSpecificationsObjectBrickAttribute,
} from '@emico/graphql-schema-types/src'
import { minWidth } from '@emico/styles'

import { Image as ImageStyled } from './styled'
import { getImage, getPrice, getSpecifications } from './utils'
import NextLink from '../../components/NextLink'
import { useStoreConfig } from '../../lib/storeConfig'
import theme, { getTypography } from '../../theme'
import { showProductModal } from '../modal/Modal'
import { Product } from '../product/product'
import { Loading } from '../skeleton/Loading'
import { HeadingTwo, SmallText, Span } from '../ui/Typography'

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 50%;
  min-width: 50%;
  min-height: 540px;
  border: 1px solid ${theme.colors.gray};

  &:nth-of-type(even) {
    border-right: none;
    border-left: none;
  }

  &:nth-of-type(odd) {
    border-left: none;
  }

  &:last-child {
    border: 1px solid ${theme.colors.gray} !important;
  }

  @media ${minWidth('lg')} {
    max-width: 282px;
    min-width: initial;
    border: 1px solid ${theme.colors.gray} !important;
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const Content = styled(Block)`
  justify-content: space-between;
  height: 100%;
  padding: ${theme.spacing.x2};
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${theme.spacing.x3} ${theme.spacing.x2};

  @media ${minWidth('lg')} {
    padding: ${theme.spacing.x2};
  }
`

const Image = styled(ImageStyled)`
  height: 126px;

  @media ${minWidth('lg')} {
    height: 250px;
  }
`

const Specifications = styled(SmallText)`
  margin-bottom: ${theme.spacing.x1};

  @media ${minWidth('lg')} {
    margin-bottom: ${theme.spacing.x2};
  }
`

const CardPrice = styled(Span)`
  margin-bottom: ${theme.spacing.x15};
`

const StyledNextLink = styled(NextLink, {
  shouldForwardProp: (prop) => !['isDisabled'].includes(prop.toString()),
})<{ isDisabled?: boolean }>`
  ${getTypography('bodyGalano', 14, 400)}
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 48px;
  padding: ${theme.spacing.x15} ${theme.spacing.x3};
  margin-bottom: ${theme.spacing.x15};
  cursor: ${({ isDisabled }) => isDisabled && 'not-allowed'};
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  background-color: ${({ isDisabled }) => isDisabled && theme.colors.gray};
  border: ${({ isDisabled }) =>
    isDisabled
      ? `1px solid ${theme.colors.gray}`
      : `1px solid ${theme.colors.black}`};
  color: ${({ isDisabled }) =>
    isDisabled ? theme.colors.darkGray : theme.colors.black};
  opacity: ${({ isDisabled }) => isDisabled && '0.4'};

  &:hover {
    background-color: ${({ isDisabled }) =>
      isDisabled ? theme.colors.gray : theme.colors.darkBlue};
    color: ${theme.colors.white};
  }
`

const DetailsButton = styled.button`
  ${getTypography('bodyProxima', 13, 400)}
  text-decoration-line: underline;
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;
`

interface Props {
  item: Product
}

export const Card = ({ item }: Props) => {
  const { name, hash, manufacturer, specifications } = item

  const { storeConfig } = useStoreConfig()

  const handleProductModal = (id: string) =>
    showProductModal({ open: true, id })

  return (
    <StyledCard>
      <ImageContainer>
        {item.price ? (
          <NextLink href={`/configurator?h=${hash}`}>
            <Image src={getImage(item)} alt={item.name ?? ''} />
          </NextLink>
        ) : (
          <Image src={getImage(item)} alt={item.name ?? ''} />
        )}
      </ImageContainer>

      <Content>
        <Block>
          <SmallText marginBottom={theme.spacing.x1}>
            {manufacturer?.name}
          </SmallText>

          {item.id}
          <HeadingTwo
            color={theme.colors.black}
            marginBottom={theme.spacing.x1}
          >
            {name}
          </HeadingTwo>

          {specifications && item.categories && (
            <Specifications>
              {getSpecifications(
                specifications as PimcoreSpecificationsObjectBrickAttribute[],
                item.categories as PimcoreObjectCategory[],
              )}
            </Specifications>
          )}
        </Block>

        <Block>
          {item.price ? (
            <CardPrice isBold>
              {getPrice(item, storeConfig?.defaultDisplayCurrencyCode)}
            </CardPrice>
          ) : (
            <Loading
              width="50%"
              height="24px"
              marginBottom={theme.spacing.x15}
            />
          )}

          <StyledNextLink
            href={`/configurator?h=${hash}`}
            isDisabled={!item.price}
          >
            <Trans>Configure</Trans>
          </StyledNextLink>

          <DetailsButton onClick={() => handleProductModal(item.id ?? '')}>
            <Trans>Show details</Trans>
          </DetailsButton>
        </Block>
      </Content>
    </StyledCard>
  )
}
