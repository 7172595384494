import styled from '@emotion/styled'

import { minWidth } from '@emico/styles'

import { Card } from './Card'
import theme from '../../theme'
import { Product } from '../product/product'

const CardGridStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: ${theme.spacing.x25};

  @media ${minWidth('lg')} {
    gap: ${theme.spacing.x25};
  }
`

interface Props {
  items: Product[]
}

export const CardGrid = ({ items }: Props) => (
  <CardGridStyled>
    {items.map((item) => (
      <Card key={item.id} item={item} />
    ))}
  </CardGridStyled>
)
